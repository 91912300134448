import { useEffect } from 'react';
import { COLOR_FUNCTIONS } from '../../constants/index';
import DotTextDateComponent from './DotTextDateComponent';

const AlertBoxComponent = ({ params, data, loading, pdfDelegate }) => {
    const {
        primaryTextTemplate,
        datePreamble,
        dateField,
        link_href_field,
        link_text_field,
        colorFunction,
        colorInputField,
        visibilityField
    } = params;

    const combinedData = {
        ...params.data,
        ...(Array.isArray(data) && data.length > 0 ? data[0] : data)
    };
    const colorInput = combinedData[colorInputField];
    const colorScheme = (
        COLOR_FUNCTIONS[colorFunction] ||
        (() => {
            return undefined;
        })
    )(colorInput);

    const divStyle = {
        ...(colorScheme
            ? {
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: colorScheme['primary'],
                  backgroundColor: colorScheme['secondary']
              }
            : {})
    };

    const link_href = combinedData[link_href_field];
    const link_text = combinedData[link_text_field];
    const visible = !!combinedData[visibilityField];

    const dotTextDateParams = {
        primaryTextTemplate,
        datePreamble,
        dateField,
        colorFunction,
        colorInput,
        data: combinedData
    };

    useEffect(() => {
        if (pdfDelegate && !loading) {
            if (data) {
                pdfDelegate?.setPdfChildCompleteFlag(true);
            } else {
                pdfDelegate?.setPdfChildErrorFlag(true);
            }
        }
    }, [pdfDelegate, loading, data]);

    return (
        visible &&
        !loading && (
            <div className={`m-0.5 py-2 px-2 flex w-full`} style={divStyle}>
                <DotTextDateComponent params={dotTextDateParams} />
                <a
                    className="text-xs text-link underline mx-1 flex flex-row items-center space-x-1"
                    target="_blank"
                    rel="noreferrer"
                    href={link_href}>
                    {link_text}
                </a>
            </div>
        )
    );
};

export default AlertBoxComponent;

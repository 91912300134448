import { useState, useEffect, useContext } from 'react';
import { numberFormat, replaceTemplateStrings } from '../../utilities';
import { Radio } from 'antd';
import RadioButtonGenericFilterEditorSchema from './RadioButtonGenericFilterEditorSchema.json';
import { FiltersContext, FiltersActions } from '../../contexts/FiltersContext';
import { Filters } from '../../constants';

const RadioButtonGenericFilter = ({ params, loading, data, pdfDelegate }) => {
    const {
        title,
        dynamicTitle,
        noDataTitle,
        defaultFilter = false,
        filterField = '',
        filterValues = [] // e.g. [{value: '1-year', label: '1 year'},{value: '3-year', label: '3 year'}]
    } = params;
    const [selection, setSelection] = useState(false);
    const [{ filterSelections }, dispatch] = useContext(FiltersContext);

    const computedFilterValues = data || filterValues || [];

    useEffect(() => {
        if (filterSelections) {
            const thisFilter = filterSelections[Filters.GENERIC_RADIO_BUTTON];
            // console.log('filterSelections: ',thisFilter)
            if (thisFilter && thisFilter[filterField] && thisFilter[filterField] !== '') {
                setSelection(thisFilter[filterField]);
            } else {
                // console.log('dispatch filter state', {defaultFilter, computedFilterValues})
                let payloadValue = {
                    ...thisFilter,
                    // additive: true
                };
                // console.log('payload Value: ',payloadValue)
                const defaultFilterFound = computedFilterValues.find((item) => {
                    return item.value == defaultFilter;
                });
                // console.log('default filter found: ',defaultFilterFound)
                const firstFilter = (computedFilterValues.length > 0 && computedFilterValues[0]).value;
                payloadValue[filterField] = (defaultFilterFound && defaultFilter) || firstFilter || '';
                dispatch({
                    type: FiltersActions.SET_FILTER_STATE,
                    payload: {
                        filterId: Filters.GENERIC_RADIO_BUTTON,
                        value: payloadValue
                    }
                });
            }
        }
    }, [filterSelections, filterField, computedFilterValues]);

    const handleClickFilter = (clickValue) => {
        const thisFilter = filterSelections[Filters.GENERIC_RADIO_BUTTON];
        let payloadValue = {
            ...thisFilter
        };
        payloadValue[filterField] = clickValue.target.value;
        dispatch({
            type: FiltersActions.SET_FILTER_STATE,
            payload: {
                filterId: Filters.GENERIC_RADIO_BUTTON,
                value: payloadValue
            }
        });
    };

    const noData = !data || (Array.isArray(data) && data.length == 0);

    const renderedTitle =
        noDataTitle && noData
            ? noDataTitle
            : !!data && !!dynamicTitle
                ? Array.isArray(data) && data.length > 0
                    ? replaceTemplateStrings(dynamicTitle, data[0])
                    : replaceTemplateStrings(dynamicTitle, data)
                : title;


    useEffect(() => {
        if (pdfDelegate && !loading && !!computedFilterValues) {
            const newline = { text: ' ', style: 'paragraph' };
            const txt = filterValues.find((value) => {return value.value === selection})?.label
            const contents = [
                {
                    text: [renderedTitle + ':  ', { text: txt, style: 'paragraph_big_bold' }],
                    style: 'paragraph_big'
                },
                newline
            ];
            pdfDelegate?.setPdfChildCompleteFlag(contents);
        }
    }, [pdfDelegate, loading, renderedTitle]);

    return (
        <div className={`flex flex-row items-center`}>
            <h2>{renderedTitle}</h2>
            <Radio.Group onChange={handleClickFilter} value={selection} className="mx-4">
                {computedFilterValues.map((filterValue) => {
                    return <Radio.Button value={filterValue.value}>{filterValue.label}</Radio.Button>;
                })}
            </Radio.Group>
        </div>
    );
};

export { RadioButtonGenericFilter, RadioButtonGenericFilterEditorSchema };

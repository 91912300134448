import * as Icons from '@ant-design/icons';
import { COLOR_FUNCTIONS } from '../../constants/index';
import { useHistory } from 'react-router-dom';

const IconButtonComponent = ({ params, action }) => {
    const {
        text,
        icon,
        iconAfter = false,
        link,
        colorFunction,
        colorInput,
        noBorder = false,
        hideBackground = false
    } = params;
    const history = useHistory();
    const Icon = Icons[icon];

    const colorScheme = (
        COLOR_FUNCTIONS[colorFunction] ||
        (() => {
            return undefined;
        })
    )(colorInput);

    const linkStyle = {
        ...(colorScheme
            ? {
                  borderColor: colorScheme['primary'],
                  backgroundColor: hideBackground ? null : colorScheme['secondary'],
                  color: colorScheme['text']
              }
            : {})
    };

    const moreClassNames =
        (colorScheme ? '' : ' border-link text-link ') +
        (action || link ? 'cursor-pointer ' : 'cursor-default ') +
        (noBorder ? '' : ' border ');

    return (
        <div className={`m-0.5 items-center flex items-center justify-center cursor-pointer`}>
            <a
                href={!link || link.startsWith('/') ? null : link}
                onClick={
                    link
                        ? () => {
                              if (link.startsWith('/')) {
                                  history.push(link);
                                  return false;
                              }
                          }
                        : action
                }
                style={linkStyle}
                className={
                    'block my-1 md:my-0 rounded-xs-sm py-1 px-1 text-center text-sm leading-none ' + moreClassNames
                }>
                {Icon && !iconAfter && <Icon style={{ marginRight: 5 }} />}
                {link ? <u>{text}</u> : text}
                {Icon && iconAfter && <Icon style={{ marginLeft: 5 }} />}
            </a>
        </div>
    );
};

export default IconButtonComponent;
